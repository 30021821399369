import React from "react";
import Layout from "../../components/Layout";

export default function solucionesDeComunicacion() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/9.jpg)`}}
                    >
                        <h1>Soluciones de Comunicación</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Gestión de comunicación y desarrollo de chatbots basados en la plataforma WhatsApp.
                            Soluciones a la medida de las necesidades de su organización, ya sea educativa, sanitaria o
                            empresarial. Simplifica la comunicación con la comunidad de usuarios para diversificar y
                            socializar servicios, dosificar y diseminar contenidos o fortalecer imagen pública de
                            funcionarios gubernamentales.
                            Nuestras soluciones permiten la generación de big data y la visualización de analíticas para
                            toma de decisiones.
                            Conozca un poco más de nuestro portafolio de servicios:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Gestión del aprendizaje y dosificación de contenidos académico, monitoreo del desempeño,
                                pase de lista y calificaciones.
                            </li>
                            <li>Vinculación universitaria y servicios escolares: oferta educativa, eventos, información
                                general, programación, etc.
                            </li>
                            <li>Gestión de comunicación en alcaldías: servicios, noticias, trámites, información
                                general, etc.
                            </li>
                            <li>Desarrollo de proyectos a la medida de su organización</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}